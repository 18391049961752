
.content-container {
    z-index: -2;
}

.title {
    font-size: 20pt;
}

.content-confirmation {
    padding-top: 15px;
    color: #095e8c;
    font-family: 'Sacramento', serif;
    font-size: 25pt;
}

.content-title {
    font-size: 45pt;
    font-family: 'Sacramento', serif;
    color: #7d684c;
    height: 100%;
    position: relative;
    z-index: 3;
    padding-top: 25px;
}

.content-title::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    box-shadow: 5px 10px;
}

.header {
    margin-bottom: 5vh;
}

.card-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
}