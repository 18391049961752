@import url(https://fonts.googleapis.com/css2?family=Estonia&family=Montserrat:wght@600;700&family=Nanum+Myeongjo&family=Playfair+Display&family=Raleway:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&family=Sacramento&display=swap);
@font-face {
  font-family: 'MagnoliaSky';
  src: local('MagnoliaSky'), url(/static/media/magnolia_sky.a8ca1033.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
	scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-image: url(/static/media/background.daf39459.png);
  animation: animateBackground 500s linear;
  animation-iteration-count: infinite;
}

.App-fade-in {
	animation: animateFadeIn 1s ease-in;
}

@keyframes animateFadeIn {
	0% { opacity: 1 }
	35% { opacity: 1 }
	100% { opacity: 0 }
}

.bg-reset-top {
	position: relative;
}

.bg-reset-top::after {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: '';
	background-color: white;
	animation: animateFadeIn 4s ease-in;
	animation-fill-mode: forwards;
}

.bg-reset {
	background-color: white;
}

.arrow-icon {
	mix-blend-mode: color-burn;
	box-sizing: border-box;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	transform: rotate(0deg);
	width: 40px;
	height: 40px;
	z-index: 3;
	margin-top: auto;
	bottom: 3vh;
}

.arrow-icon::after, .arrow-icon::before {
	content: '';
	box-sizing: border-box;
	display: block;
	position: absolute;
	border-radius: 10px;
	background: #7d684c;
	width: 20px;
	height: 4px;
}

.arrow-icon::after {
	transform: rotate(44deg);
	left: 4px;
}
.arrow-icon::before {
	transform: rotate(-44deg);
	right: 4px;
}

.landing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #7d684c;
  position: relative;
  overflow: hidden;
}

@keyframes animateBackground {
	0% { background-position: 1500px 2200px; }
	50% { background-position: 2000px 2700px; }
	100% { background-position: 1500px 2200px; }
}

.language-picker-wrapper {
	position: fixed;
	z-index: 999;
	top: 10px;
	right: 10px;
}

.text-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 60vw;
  z-index: 1;
  align-items: center;
}

.date-wrapper {
	display: flex;
	align-items: center;
	margin-top: -15px;
	animation: animateFadeInDate 3s ease-out;
	animation-fill-mode: forwards;
}

@keyframes animateFadeInDate {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

.landing-title {
	font-family: 'Montserrat', sans-serif;
	letter-spacing: 0.3em;
	text-transform: uppercase;
	font-size: 8pt;
	margin-bottom: 10px;
	animation: animateFadeInTitle 3s ease-in;
	animation-fill-mode: forwards;
}

@keyframes animateFadeInTitle {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

.month, .year {
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	border: 1px solid black;
	border-right: 0;
	border-left: 0;
	padding: 5px 0;
}

.day {
	font-family: 'MagnoliaSky', serif;
	font-size: 3em;
	padding: 0 10px;
}

.background-hexagon {
	width: 75vw;
	height: 75vw;
	position: absolute;
	-webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
	        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
	max-width: 550px;
	mix-blend-mode: screen;
}

.logo {
  width: 50vw;
  max-width: 350px;
  animation: animateFadeInTitle .5s ease-in;
  animation-fill-mode: forwards;
}

.hexagon-container {
	display: flex;
	height: 100vh;
	width: 75vw;
	position: relative;
	justify-content: center;
	align-items: center;
}

.desc {
  font-family: 'Playfair Display', serif;
  padding: 10px 0;
}

.particle-container{
  position:relative;
  width:100%;
  height:100vh;
}

.content {
  min-height: 100vh;
  background-color: #FFF;
}

@media only screen and (max-width: 770px) {
  html, body, #root, .App, .landing-page {
    width: 100%;
  }

  .background-hexagon {
	  width: 95vw;
	  height: 95vw;
  }

  .logo {
	  width: 70vw;
  }

  .content-container .card-container {
    grid-template-columns: 1fr;
  }

  .card {
	  flex-direction: column;
	  align-items: center;
  }

  .detail-image {
	  max-width: 90%;
  }
}
.localization-container {
	height: 40px;
	width: 140px;
}

.country-flag {
    cursor: pointer;
	height: 25px;
	margin-left: 10px;
    opacity: .4;
    transition: all ease-in .2s;
}

.country-flag:hover {
    opacity: 1;
}

.country-flag.active {
    opacity: 1;
}
.img-wrapper {
    height: 40vh;
    width: 40vh;
    margin-right: 15px;
    position: relative;
    z-index: 1;
}

.detail-description {
    text-align: left;
    text-align: initial;
}

.detail-image {
    height: 300px;
    width: 400px;
    object-fit: cover;
    border-radius: 15px;
    position: relative;
}

.card-cell {
    padding: 15px;
    width: 260px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.card {
    display: flex;
    justify-content: center;
    margin: 25px 0;
}

.desc-link {
    color: #009FB7;
    font-size: 11pt;
}

.card-title {
    font-family: 'Sacramento', sans-serif;
    color: #7d684c;
    font-size: 33pt;
    text-align: left;
    text-align: initial;
    line-height: 50px;
}

.icon {
    height: 12px;
}

.card-text {
    font-size: 15pt;
    margin-bottom: 2vh;
}

.card-link {
    margin-top: 5px;
    text-decoration: none;
    color: #fff;
    background-color: #009FB7;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    z-index: 99999;
    padding: 0 10px;
    height: 35px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-icon {
    width: 15px;
    margin-right: 5px;
}

.content-container {
    z-index: -2;
}

.title {
    font-size: 20pt;
}

.content-confirmation {
    padding-top: 15px;
    color: #095e8c;
    font-family: 'Sacramento', serif;
    font-size: 25pt;
}

.content-title {
    font-size: 45pt;
    font-family: 'Sacramento', serif;
    color: #7d684c;
    height: 100%;
    position: relative;
    z-index: 3;
    padding-top: 25px;
}

.content-title::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    box-shadow: 5px 10px;
}

.header {
    margin-bottom: 5vh;
}

.card-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.footer-wrapper {
    position: relative;
    padding: 20px 0 50px;
    background-color: #fff;
}

.contact {
    padding-top: 30px;
}

.footer-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    box-shadow: 5px 10px;
}


.link {
    color: #009FB7;
    display: block;
}
.info-container { 
    background-color: white;
}

.info-title {
    font-size: 44pt;
    font-family: 'Sacramento', serif;
    color: #7d684c;
    position: relative;
    padding-top: 4vh;
}

.info-text {
    padding-bottom: 4vh;
    max-width: 800px;
}

.info-description {
    max-width: 500px;
    margin: 30px auto;
    padding: 0 15px;
}
