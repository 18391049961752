.footer-wrapper {
    position: relative;
    padding: 20px 0 50px;
    background-color: #fff;
}

.contact {
    padding-top: 30px;
}

.footer-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    box-shadow: 5px 10px;
}


.link {
    color: #009FB7;
    display: block;
}