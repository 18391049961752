.info-container { 
    background-color: white;
}

.info-title {
    font-size: 44pt;
    font-family: 'Sacramento', serif;
    color: #7d684c;
    position: relative;
    padding-top: 4vh;
}

.info-text {
    padding-bottom: 4vh;
    max-width: 800px;
}

.info-description {
    max-width: 500px;
    margin: 30px auto;
    padding: 0 15px;
}