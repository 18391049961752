html {
	scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-image: url('./assets/background.png') ;
  animation: animateBackground 500s linear;
  animation-iteration-count: infinite;
}

.App-fade-in {
	animation: animateFadeIn 1s ease-in;
}

@keyframes animateFadeIn {
	0% { opacity: 1 }
	35% { opacity: 1 }
	100% { opacity: 0 }
}

.bg-reset-top {
	position: relative;
}

.bg-reset-top::after {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: '';
	background-color: white;
	animation: animateFadeIn 4s ease-in;
	animation-fill-mode: forwards;
}

.bg-reset {
	background-color: white;
}

.arrow-icon {
	mix-blend-mode: color-burn;
	box-sizing: border-box;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	transform: rotate(0deg);
	width: 40px;
	height: 40px;
	z-index: 3;
	margin-top: auto;
	bottom: 3vh;
}

.arrow-icon::after, .arrow-icon::before {
	content: '';
	box-sizing: border-box;
	display: block;
	position: absolute;
	border-radius: 10px;
	background: #7d684c;
	width: 20px;
	height: 4px;
}

.arrow-icon::after {
	transform: rotate(44deg);
	left: 4px;
}
.arrow-icon::before {
	transform: rotate(-44deg);
	right: 4px;
}

.landing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #7d684c;
  position: relative;
  overflow: hidden;
}

@keyframes animateBackground {
	0% { background-position: 1500px 2200px; }
	50% { background-position: 2000px 2700px; }
	100% { background-position: 1500px 2200px; }
}

.language-picker-wrapper {
	position: fixed;
	z-index: 999;
	top: 10px;
	right: 10px;
}

.text-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 60vw;
  z-index: 1;
  align-items: center;
}

.date-wrapper {
	display: flex;
	align-items: center;
	margin-top: -15px;
	animation: animateFadeInDate 3s ease-out;
	animation-fill-mode: forwards;
}

@keyframes animateFadeInDate {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

.landing-title {
	font-family: 'Montserrat', sans-serif;
	letter-spacing: 0.3em;
	text-transform: uppercase;
	font-size: 8pt;
	margin-bottom: 10px;
	animation: animateFadeInTitle 3s ease-in;
	animation-fill-mode: forwards;
}

@keyframes animateFadeInTitle {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

.month, .year {
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	border: 1px solid black;
	border-right: 0;
	border-left: 0;
	padding: 5px 0;
}

.day {
	font-family: 'MagnoliaSky', serif;
	font-size: 3em;
	padding: 0 10px;
}

.background-hexagon {
	width: 75vw;
	height: 75vw;
	position: absolute;
	filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
	max-width: 550px;
	mix-blend-mode: screen;
}

.logo {
  width: 50vw;
  max-width: 350px;
  animation: animateFadeInTitle .5s ease-in;
  animation-fill-mode: forwards;
}

.hexagon-container {
	display: flex;
	height: 100vh;
	width: 75vw;
	position: relative;
	justify-content: center;
	align-items: center;
}

.desc {
  font-family: 'Playfair Display', serif;
  padding: 10px 0;
}

.particle-container{
  position:relative;
  width:100%;
  height:100vh;
}

.content {
  min-height: 100vh;
  background-color: #FFF;
}

@media only screen and (max-width: 770px) {
  html, body, #root, .App, .landing-page {
    width: 100%;
  }

  .background-hexagon {
	  width: 95vw;
	  height: 95vw;
  }

  .logo {
	  width: 70vw;
  }

  .content-container .card-container {
    grid-template-columns: 1fr;
  }

  .card {
	  flex-direction: column;
	  align-items: center;
  }

  .detail-image {
	  max-width: 90%;
  }
}