@import url('https://fonts.googleapis.com/css2?family=Estonia&family=Montserrat:wght@600;700&family=Nanum+Myeongjo&family=Playfair+Display&family=Raleway:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&family=Sacramento&display=swap');

@font-face {
  font-family: 'MagnoliaSky';
  src: local('MagnoliaSky'), url(./assets/magnolia_sky.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}