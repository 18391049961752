.localization-container {
	height: 40px;
	width: 140px;
}

.country-flag {
    cursor: pointer;
	height: 25px;
	margin-left: 10px;
    opacity: .4;
    transition: all ease-in .2s;
}

.country-flag:hover {
    opacity: 1;
}

.country-flag.active {
    opacity: 1;
}