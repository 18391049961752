.img-wrapper {
    height: 40vh;
    width: 40vh;
    margin-right: 15px;
    position: relative;
    z-index: 1;
}

.detail-description {
    text-align: initial;
}

.detail-image {
    height: 300px;
    width: 400px;
    object-fit: cover;
    border-radius: 15px;
    position: relative;
}

.card-cell {
    padding: 15px;
    width: 260px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.card {
    display: flex;
    justify-content: center;
    margin: 25px 0;
}

.desc-link {
    color: #009FB7;
    font-size: 11pt;
}

.card-title {
    font-family: 'Sacramento', sans-serif;
    color: #7d684c;
    font-size: 33pt;
    text-align: initial;
    line-height: 50px;
}

.icon {
    height: 12px;
}

.card-text {
    font-size: 15pt;
    margin-bottom: 2vh;
}

.card-link {
    margin-top: 5px;
    text-decoration: none;
    color: #fff;
    background-color: #009FB7;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    z-index: 99999;
    padding: 0 10px;
    height: 35px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-icon {
    width: 15px;
    margin-right: 5px;
}